import React, { useState, useEffect } from "react";
import { userAPI } from "../../../api";
import CenteredPopup from "../../../components/centered-popup/CenteredPopup";
import WPLAnimatedInput from "../../../components/wpl-animated-input/WplAnimatedInput";
import { toStandardWplTimestamp } from "../../../prettyDate";
import ShowIcon from "../../../assets/eye.png";
import HideIcon from "../../../assets/closed-eye.png";
import loadingSvg from "../../../assets/loading.svg";
import "./lightning-account-settings.css";
import WplButton from "../../../components/wpl-button/WplButton";

export default function LightningAccountSettings() {
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const errorMessages = {
    pwMatch: "The new passwords you entered don't match. Please try again.",
  };
  const [loading, setLoading] = useState(false);

  const handlePasswordUpdate = async () => {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage(errorMessages.pwMatch);
      return;
    }
    setLoading(true);

    try {
      const timestamp = toStandardWplTimestamp(new Date());
      await userAPI.updatePassword(
        currentPassword,
        newPassword,
        confirmNewPassword,
        timestamp
      );
      setSuccessMessage(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.response?.data?.error || "An error occurred");
    } finally {
      setLoading(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    }
  };

  const validateInputs = () => {
    const isDisabled = !(currentPassword && newPassword && confirmNewPassword);
    setIsBtnDisable(isDisabled);

    if (newPassword && confirmNewPassword) {
      if (newPassword !== confirmNewPassword) {
        setErrorMessage(errorMessages.pwMatch);
        setIsBtnDisable(true);
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    validateInputs();
  }, [currentPassword, newPassword, confirmNewPassword]);

  return (
    <div className="account-settings-container">
      <h4>Update Password</h4>

      {loading && (
        <div className="overlay loading">
          <img src={loadingSvg} alt="loading" />
        </div>
      )}
      <WPLAnimatedInput
        label="Current password"
        type={showPassword ? "text" : "password"}
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />

      <WPLAnimatedInput
        label="New password"
        type={showPassword ? "text" : "password"}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />

      <WPLAnimatedInput
        label="Repeat new password"
        type={showPassword ? "text" : "password"}
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />

      {errorMessage === errorMessages.pwMatch && (
        <p className="error-message">{errorMessage}</p>
      )}

      <div
        className="show-password"
        onClick={() => setShowPassword(!showPassword)}
      >
        <img
          src={showPassword ? ShowIcon : HideIcon}
          alt="Password visibility toggle icon"
          className="toggle-icon"
        />
        <p className="toggle-label">
          {showPassword ? "Hide" : "Show"} Password
        </p>
      </div>

      <CenteredPopup
        showPopup={successMessage}
        closePopup={setSuccessMessage}
        className="historic-popup"
      >
        <p className="success-message">Password successfully updated.</p>
      </CenteredPopup>

      <div className="btn-container">
        <WplButton
          value="Update"
          disabled={isBtnDisable}
          onClick={handlePasswordUpdate}
          className={`update-btn ${isBtnDisable ? "disabled" : ""}`}
        />
      </div>
    </div>
  );
}
