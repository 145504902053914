import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/img/WPL-Global-Blade-Experts-04.png";
import "./navbar.css";
import lassieIcon from "../../assets/img/LASSIE-LOGO-Blue-Dog.png";
import lassieIconWhite from "../../assets/img/LASSIE-LOGO-White-Dog.png";

export default function Navbar() {
  const navigate = useNavigate();

  let { windfarm_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const iconRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setIsSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <>
      <div className="navbar-container">
        <div className="image-area">
          <img
            className="nav-logo"
            alt="Wind Power Lab"
            src={logo}
            onClick={() => {
              navigate("/");
            }}
          />
          <img
            className="lassie-icon-header"
            src={lassieIcon}
            alt="LASSIE ICON"
            onClick={() => {
              navigate("/lassie");
            }}
          />
        </div>
        <div className="navigation-area">
          <Link to={`/`}>Wind Diagnostics</Link>

          <Link to={`/lassie`}>Portfolio</Link>
          <div className="dropdown">
            <a>Historic Data</a>
            <div className={`nav-dropdown-arrow-icon`}></div>
            <div className="dropdown-content">
              <Link
                to={`historic-lightning${
                  windfarm_id ? `/windfarm/${windfarm_id}` : ""
                }`}
              >
                Lightning Analysis
              </Link>
              <Link
                to={`report${windfarm_id ? `/windfarm/${windfarm_id}` : ""}`}
              >
                Logbook
              </Link>
            </div>
          </div>
          <Link
            to={`dashboard${windfarm_id ? `/windfarm/${windfarm_id}` : ""}`}
          >
            Dashboard
          </Link>
          <a
            href="https://weather.dtn.com/dtnweather/"
            target="_blank"
            rel="noopener noreferrer"
          >
            DTN Weather Sentry
          </a>
        </div>
        <div
          ref={sidebarRef}
          className={`sidebar ${isSidebarOpen ? "out" : ""}`}
        >
          <ul>
            <Link to="/lassie">
              <li>
                <div>Home</div>
              </li>
            </Link>
            <Link to="/lassie/settings/notifications">
              <li>
                <div>Settings</div>
              </li>
            </Link>
            <Link to="/lassie/support">
              <li>
                <div>Support & FAQ</div>
              </li>
            </Link>
            <Link to="/logout">
              <li>
                <div>Log out</div>
              </li>
            </Link>
          </ul>
          <div className="bottom-image">
            <img
              className="lassie-icon"
              src={lassieIconWhite}
              alt="LASSIE icon"
              onClick={() => {
                navigate("/lassie");
              }}
            />
          </div>
        </div>
        <div className="sidebar-area">
          <div
            ref={iconRef}
            className={`icon ${isSidebarOpen ? "out" : ""}`}
            onClick={toggleSidebar}
          >
            <div className="bar"></div>
            <div className="bar mdl"></div>
            <div className="bar"></div>
          </div>
        </div>
      </div>
    </>
  );
}
