import React, { useState, useContext } from "react";
import { LassieContext } from "../../../context/LassieProvider";
import "./manage-lightning-inspection.css";
import { useParams } from "react-router-dom";
import TurbineListFriendly from "../turbine-list-friendly/TurbineListFriendly";
import CreateUserAction from "../../../components/create-user-action/CreateUserAction";

export default function ManageLightningInspection() {
  let { windfarm_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }
  const { turbinesStatus } = useContext(LassieContext);
  const [turbineIds, setTurbineIds] = useState([]);
  const [selectedTurbines, setSelectedTurbines] = useState([]);

  const clickedTurbine = (turbine) => {
    setTurbineIds((prevState) => {
      if (prevState.indexOf(turbine.id) === -1) {
        return [...prevState, turbine.id];
      } else {
        return prevState.filter((id) => id !== turbine.id);
      }
    });
    setSelectedTurbines((prevState) => {
      if (prevState.some((t) => t.id === turbine.id)) {
        return prevState.filter((t) => t.id !== turbine.id);
      } else {
        return [...prevState, turbine];
      }
    });
  };

  const cleanSelected = () => {
    setTurbineIds([]);
    setSelectedTurbines([]);
  };

  return (
    <div className={`manage-lightning-inspection`}>
      <h1 className="title">Managing open cases</h1>
      <div className="turbine-risk-wrapper">
        {turbinesStatus.toBeInspected.map((tr) => (
          <div
            key={tr.id}
            className="turbine-marked"
            onClick={() => clickedTurbine(tr)}
          >
            <input
              type="checkbox"
              checked={turbineIds.indexOf(tr.id) !== -1}
              onChange={(_) => {}}
            />
            <TurbineListFriendly
              tr={tr}
              key={tr.id}
              className={`turbine-risk ${
                turbineIds.indexOf(tr.id) === -1 ? "" : "selected"
              }`}
              selected={turbineIds.indexOf(tr.id) !== -1}
            />
          </div>
        ))}
      </div>

      <a
        href="https://windpowerlab.com/blade-advice/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Need assistance with assessment? Get our expert advice through image to
        advice
      </a>

      <CreateUserAction
        turbineIds={turbineIds}
        selectedTurbines={selectedTurbines}
        cleanSelected={cleanSelected}
      />
    </div>
  );
}
