import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { LassieContext } from "../../context/LassieProvider";
import WplFileUpload from "../wpl-file-upload/WplFileUpload";
import WplInput from "../wpl-input/WplInput";
import { toPrettyDateStr } from "../../prettyDate";
import WplButton from "../wpl-button/WplButton";
import { useParams } from "react-router-dom";
import "./create-user-action.css";

export default function CreateUserAction({turbineIds, selectedTurbines, cleanSelected}) {
  const {
    createUserAction,
    turbinesStatus,
  } = useContext(LassieContext);
  const [userComment, setUserComment] = useState("");
  const [targetDate, setTargetDate] = useState(toPrettyDateStr(new Date()));
  const [uploadedDocuments, setUploadedDocuments] = useState({});

  let { windfarm_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }

  const turbinesMarkedForInspection = turbinesStatus.toBeInspected;

  const datePickerRef = useRef();
  const openDatePicker = useCallback(() => {
    if (!datePickerRef.current) return;
    datePickerRef.current.showPicker();
  }, [datePickerRef]);

  const uploadedFile = useCallback(
    (filename, data) => {
      setUploadedDocuments((p) => ({ ...p, [filename]: data }));
    },
    [setUploadedDocuments]
  );

  const latestTurbineUserAction = new Date(
    Math.max(
      ...((Array.isArray(selectedTurbines) ? selectedTurbines : []).map(
        (t) => new Date(t.latest_user_action.target_date)
      ))
    )
  );
  

  const tooManyTurbinesSelected = useMemo(() => {
    return Object.keys(uploadedDocuments).length > 0 && turbineIds.length > 1;
  }, [uploadedDocuments, turbineIds]);

  if (!turbinesMarkedForInspection) return null;

  return (
    <div className="create-user-action">
      <div>
        <p htmlFor="comment-or-note">
          Add Comment or note for wind turbine action
        </p>
        <textarea
          id="comment-or-note"
          style={{ width: "350px", height: "125px" }}
          value={userComment}
          onChange={(e) => setUserComment(e.target.value)}
        />
      </div>

      <WplFileUpload
        text="Upload inspection-related documents (optional)"
        asPopup={false}
        displayFiles={Object.keys(uploadedDocuments).length > 0}
        style={{
          width: "450px",
        }}
        didSelectFile={uploadedFile}
      />

      {tooManyTurbinesSelected && (
        <p style={{ color: "red" }}>
          You can only upload documents to one turbine at a time.
        </p>
      )}
      <div className="btn-wrap">
        <WplInput
          min={
            latestTurbineUserAction
              ? toPrettyDateStr(latestTurbineUserAction)
              : ""
          }
          inputRef={datePickerRef}
          onClick={openDatePicker}
          className="performed-inspection-date"
          type="date"
          error={
            latestTurbineUserAction > new Date(targetDate)
              ? `Must select a date after ${toPrettyDateStr(
                  latestTurbineUserAction
                )}`
              : ""
          }
          value={targetDate}
          onChanged={(e) => setTargetDate(e)}
        />

        {userComment && selectedTurbines.length === 0 && (
          <p>No turbines selected</p>
        )}
        <WplButton
          className="report-btn"
          disabled={
            !targetDate ||
            selectedTurbines.length === 0 ||
            tooManyTurbinesSelected
          }
          value="Update and keep"
          onClick={() => {
            createUserAction({
              turbine_ids: turbineIds,
              action: "marked_for_inspection",
              target_date: targetDate,
              user_comment: userComment,
              file_names: Object.keys(uploadedDocuments),
              file_datas: Object.keys(uploadedDocuments).map(
                (k) => uploadedDocuments[k]
              ),
            });
            setUserComment("");
            setTargetDate("");
            setUploadedDocuments({});
          }}
        />
        <WplButton
          className="report-btn"
          red
          disabled={
            !targetDate ||
            selectedTurbines.length === 0 ||
            tooManyTurbinesSelected
          }
          value="Mark as inspected and close"
          onClick={() => {
            createUserAction({
              turbine_ids: turbineIds,
              action: "inspection",
              target_date: targetDate,
              user_comment: userComment,
              file_names: Object.keys(uploadedDocuments),
              file_datas: Object.keys(uploadedDocuments).map(
                (k) => uploadedDocuments[k]
              ),
            });
            setUserComment("");
            setTargetDate("");
            setUploadedDocuments({});
            cleanSelected()
          }}
        />
      </div>
    </div>
  );
}
